<template>
  <Layout>
    <div class="loginBox pa-6">
      <ValidationObserver ref="observer">
        <form @submit.prevent="tryToLogIn">
          <h3 class="text-h5 font-weight-medium">
            {{ visualSettings.frontPageBoxTitle }}
          </h3>
          <p class="subtitle-1">
            Inserisci i tuoi dati per accedere all'area riservata.
          </p>

          <ValidationProvider
            v-slot="{ errors }"
            name="Username"
            rules="required"
          >
            <v-text-field
              v-model.trim="username"
              :error-messages="errors"
              label="Username"
              type="text"
              filled
              rounded
              required
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            name="Password"
            rules="required|min:3"
          >
            <v-text-field
              v-model.trim="password"
              :error-messages="errors"
              label="Password"
              filled
              rounded
              :append-icon="showPassword ? 'visibility_off' : 'visibility'"
              :type="showPassword ? 'text' : 'password'"
              required
              @click:append="showPassword = !showPassword"
            />
          </ValidationProvider>

          <v-alert
            v-for="error in errors"
            :key="error.message"
            type="error"
            dense
          >
            {{ error.message }}
          </v-alert>

          <v-btn
            block
            large
            depressed
            rounded
            dark
            flat
            :color="visualSettings.accent"
            class="mt-3"
            :loading="loading"
            type="submit"
          >
            <v-icon left>arrow</v-icon>
            Accedi
          </v-btn>

          <v-btn
            block
            large
            text
            light
            :color="visualSettings.secondary"
            class="mt-3 mb-0"
            @click="loginWithSSO('google')"
          >
            <v-icon left>mdi-google</v-icon>
            Accedi con Google
          </v-btn>

          <v-btn
            block
            large
            text
            light
            :color="visualSettings.secondary"
            @click="loginWithSSO('microsoft')"
          >
            <v-icon left>mdi-microsoft</v-icon>
            Accedi con Microsoft
          </v-btn>

          <v-btn
            block
            text
            small
            :color="visualSettings.accent"
            class="mt-5"
            @click.native="recovery"
          >
            Non ricordi la password?
          </v-btn>
          <v-spacer class="mt-12 mb-12" />
          &nbsp;
        </form>
      </ValidationObserver>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/auth.vue'
import { authMethods } from '@state/helpers'
import appConfig from '@src/app.config'
import { mapState } from 'vuex'
import { PublicClientApplication } from '@azure/msal-browser'
import utils from '@mixins/utils.js'
/* import NProgress from 'nprogress' */

export default {
  page: {
    title: 'Login',
    meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }],
  },

  components: { Layout },
  mixins: [utils],
  data: () => ({
    username: process.env.VUE_APP_DEFAULT_USERNAME || null,
    password: process.env.VUE_APP_DEFAULT_PASSWORD || null,
    showPassword: false,
    errors: [],
  }),
  computed: {
    ...mapState('auth', ['loading']),
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 180
        case 'sm':
          return 240
        default:
          return 360
      }
    },
  },
  created() {
    this.msal = new PublicClientApplication({
      auth: {
        clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
      },
    })
  },
  methods: {
    ...authMethods,
    async tryToLogIn() {
      this.errors = []
      try {
        await this.logIn({
          username: this.username,
          password: this.password,
        })
        return this.$router.push(
          this.$route.query.redirectFrom || { name: 'home' }
        )
      } catch (error) {
        if (error.message) this.errors = [...this.errors, error]
        console.log('ops errore', error)
      }
    },
    async loginWithSSO(provider) {
      try {
        this.errors = []
        let authCode
        switch (provider) {
          case 'google':
            authCode = await this.$gAuth.getAuthCode()
            break
          case 'microsoft':
            var login = await this.msal.loginPopup({
              scopes: ['openid', 'User.Read'],
            })
            authCode = login.idToken
            break

          default:
            throw Error('Provider non valido')
        }

        await this.logIn({
          authCode,
          provider,
        })

        await this.$router.push(
          this.$route.query.redirectFrom || { name: 'home' }
        )
      } catch (error) {
        if (error.message) this.errors = [...this.errors, error]
        console.log('ops errore', error)
      }
    },
    recovery() {
      this.$router.push({ name: 'recovery' })
    },
  },
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.loginBox {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
}
</style>
